import { FrideIngredient } from "../types"

/**
 * Store the ingredients in the localstorage
 * TODO: handling duplicates
 */
export const storeIngredients = (ingredients: FrideIngredient[]) => {
  if (typeof window !== `undefined` && window) {
    const dataString = JSON.stringify(ingredients)
    window.sessionStorage.setItem("tastyfridgeContent", dataString)
  }
}

/**
 * Checks if ingredients already exists in storage and adds if if it doesn't
 * @param ingredient
 */
export const addIngredient = (ingredient: FrideIngredient) => {
  if (typeof window !== `undefined` && window) {
    const prevIngredients = getIngredients()
    const dataString = JSON.stringify([ingredient, ...prevIngredients])
    window.sessionStorage.setItem("tastyfridgeContent", dataString)
  }
}

/**
 * Get the ingredients from the localstorage
 */
export const getIngredients = (): FrideIngredient[] => {
  if (typeof window !== `undefined` && window) {
    if (hasIngredients()) {
      const dataString = window.sessionStorage.getItem("tastyfridgeContent")
      return JSON.parse(dataString)
    } else {
      console.log("no ingredients found")
      return []
    }
  } else {
    return []
  }
}

/**
 * Checks if there are ingredients saved in localstorage
 * @returns boolean
 */
export const hasIngredients = () => {
  if (typeof window !== `undefined` && window) {
    if (window.sessionStorage.getItem("tastyfridgeContent")) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

/**
 * Compares current ingredients with localstorage ingredients
 * @returns boolean
 */
export const shouldUpdate = () => {}

/**
 * Removes a single ingredient from list and updates it
 * @param ingredient string name of the ingredient to be deleted
 */
export const deleteIngredient = (ingredient: string) => {
  const ingredientsList = getIngredients().filter(
    ing => ing.name !== ingredient
  )
  storeIngredients(ingredientsList)
}

/**
 * Removes the entire ingredientslist
 */
export const clearIngredients = () => {
  if (typeof window !== `undefined` && window) {
    window.sessionStorage.removeItem("tastyfridgeContent")
  } else {
    console.error("error clearing ingredients")
  }
}
