import React from "react"
import { deleteIngredient, clearIngredients } from "../utils/storage"
import { Icon, InlineIcon } from "@iconify/react"
import crossSmall from "@iconify/icons-gridicons/cross-small"
import { navigateTo } from "gatsby"

type FrideIngredient = {
  type: string
  name: string
  parent: string
  quantity: number
  unit: string
}

type FridgeContents = {
  id: number
  parent: null
  type: string
  category: string
  content: FrideIngredient[]
}

const Ingredient = ({ ingredient }) => {
  return (
    <li className="inline-block px-3 py-2 text-sm font-semibold text-gray-700 m-0 w-full">
      <p>
        {ingredient.name}
        <button onClick={() => deleteIngredient(ingredient.name)}>
          <InlineIcon
            icon={crossSmall}
            style={{ fontSize: "30px" }}
            className="inline"
          />
        </button>
      </p>
    </li>
  )
}

const FridgeContent = ({ content }) => {
  return (
    <ul className="divide-y divide-gray-400 mx-0">
      {content.map((ingredient, i) => (
        <Ingredient ingredient={ingredient} key={"ingredient" + i} />
      ))}
      <button
        className=" h-12 px-2 content-center align-middleshadow bg-bgDeepBlue hover:bg-bgBlue focus:shadow-outline focus:outline-none text-white font-bold py-2 px-2 rounded"
        onClick={() => navigateTo("/recipe-suggestions/")}
      >
        Get Recipe Suggestions
      </button>

      <button
        onClick={() => clearIngredients()}
        className="h-12 ml-4 shadow bg-red-700 hover:bg-red-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-2 rounded"
      >
        <InlineIcon
          icon={crossSmall}
          style={{ color: "#F6F9FC", fontSize: "30px" }}
          className="inline-block content-center"
        />
        <span className="px-2 content-center align-middle">Delete All</span>
      </button>
    </ul>
  )
}

export default FridgeContent
