// Gatsby supports TypeScript natively!
import React, { useEffect, useState } from "react"
import { PageProps, Link } from "gatsby"
import { Icon, InlineIcon } from "@iconify/react"
import bxListPlus from "@iconify/icons-bx/bx-list-plus"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FridgeContent from "../components/fridgeContent"
import { FridgeCategory, Recipe, FrideIngredient } from "../types"
import {
  storeIngredients,
  getIngredients,
  clearIngredients,
} from "../utils/storage"

const testIngredients: FridgeCategory[] = [
  {
    id: 1,
    parent: null,
    category: "dairy",
    content: [
      {
        parent: "dairy",
        name: "milk",
        type: "ingredient",
      },
      {
        parent: "dairy",
        name: "butter",
        type: "ingredient",
      },
      {
        parent: "dairy",
        name: "yogurt",
        type: "ingredient",
      },
    ],
    type: "category",
  },
  {
    id: 2,
    parent: null,
    category: "vegetables",
    content: [
      {
        parent: "vegetables",
        name: "broccoli",
        type: "ingredient",
      },
      {
        parent: "vegetables",
        name: "tomatoes",
        type: "ingredient",
      },
      {
        parent: "vegetables",
        name: "garlic",
        type: "ingredient",
      },
    ],
    type: "category",
  },
  {
    id: 3,
    parent: null,
    category: "meat",
    content: [
      {
        parent: "meat",
        name: "pork",
        type: "ingredient",
      },
      {
        parent: "meat",
        name: "beef",
        type: "ingredient",
      },
    ],
    type: "category",
  },
]

const ingredients: FrideIngredient[] = [
  { name: "apple", quantity: 1 },
  { name: "milk" },
  { name: "kiwi", quantity: 1, unit: "cups" },
  { name: "ground-beef", quantity: 1 },
  { name: "tortelini", quantity: 1 },
  { name: "pasta", quantity: 1 },
  { name: "tuna", quantity: 1 },
]

const MyFridge = (props: PageProps) => {
  const [ingredientsList, setIngredientsList] = useState(getIngredients())
  useEffect(() => {
    // function that gets the ingredients from localstorage
  }, [])
  return (
    <Layout>
      <SEO title="My Fridge" />
      <h1>My Fridge</h1>
      {ingredientsList.length > 0 ? (
        <FridgeContent content={ingredientsList} />
      ) : (
        <>
          <h4>
            <i>*Crickets*</i> Add some ingredients and come back here.
          </h4>
          <button className="shadow bg-bgGreen hover:bg-bgGreen-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-2 rounded">
            <Link to="/search-ingredients/">
              <InlineIcon
                icon={bxListPlus}
                style={{ color: "#F6F9FC", fontSize: "30px" }}
                className="inline-block content-center"
              />
              <span className="px-2 content-center align-middle">
                Add Ingredients
              </span>
            </Link>
          </button>
        </>
      )}
    </Layout>
  )
}

export default MyFridge
